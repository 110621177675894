import './main.css';
import { jsxs as I, Fragment as P, jsx as r } from "react/jsx-runtime";
import { useState as A, createContext as re, useContext as le, useCallback as ae, useEffect as se, useMemo as w, forwardRef as $, Fragment as oe, useId as Ie } from "react";
import { Modal as ve, Button as x, Popover as Te, FormGroup as ie, ValidatedOptions as v, Select as ce, SelectVariant as W, SelectOption as ue, Switch as Se, TextInput as de, TextArea as ke, NumberInput as xe, AlertGroup as we, Alert as Ae, AlertVariant as D, AlertActionCloseButton as Ve, InputGroup as B, ButtonVariant as J, Checkbox as Ne, Radio as qe, Text as Oe, Title as Le, Card as Pe, CardHeader as Re, CardTitle as Fe, CardBody as _e, Grid as De, GridItem as K, PageSection as Ee, JumpLinks as Me, JumpLinksItem as He } from "@patternfly/react-core";
import { useFormContext as G, Controller as q, useController as j, FormProvider as $e, useWatch as Be } from "react-hook-form";
import { HelpIcon as z, CubeIcon as Ge, PaypalIcon as je, InstagramIcon as Ue, BitbucketIcon as We, MicrosoftIcon as Je, TwitterIcon as Ke, StackOverflowIcon as ze, OpenshiftIcon as Ye, LinkedinIcon as Qe, GoogleIcon as Xe, GitlabIcon as Ze, FacebookSquareIcon as et, GithubIcon as tt, MinusCircleIcon as nt, PlusCircleIcon as rt, EyeIcon as lt, EyeSlashIcon as at } from "@patternfly/react-icons";
import { get as Y } from "lodash-es";
import { useTranslation as st } from "react-i18next";
const Gt = ({
  modalTitle: e,
  continueLabel: t,
  cancelLabel: n,
  buttonTitle: l,
  isDisabled: a,
  buttonVariant: s,
  buttonTestRole: o,
  onContinue: i,
  component: c = x,
  children: u,
  ...h
}) => {
  const [p, g] = A(!1);
  return /* @__PURE__ */ I(P, { children: [
    /* @__PURE__ */ r(
      c,
      {
        variant: s,
        onClick: () => g(!0),
        isDisabled: a,
        "data-testrole": o,
        children: l
      }
    ),
    /* @__PURE__ */ r(
      ve,
      {
        variant: "small",
        ...h,
        title: e,
        isOpen: p,
        onClose: () => g(!1),
        actions: [
          /* @__PURE__ */ r(
            x,
            {
              id: "modal-confirm",
              variant: "primary",
              onClick: () => {
                g(!1), i();
              },
              children: t
            },
            "confirm"
          ),
          /* @__PURE__ */ r(
            x,
            {
              id: "modal-cancel",
              variant: "secondary",
              onClick: () => g(!1),
              children: n
            },
            "cancel"
          )
        ],
        children: u
      }
    )
  ] });
};
function ot(e, t) {
  const n = re(t);
  return n.displayName = e, n;
}
function it(e) {
  return e != null;
}
function ct(e) {
  const t = le(e);
  if (it(t))
    return t;
  throw new Error(
    `No provider found for ${e.displayName ? `the '${e.displayName}'` : "an unknown"} context, make sure it is included in your component hierarchy.`
  );
}
function ut(e, t, n) {
  const [l, a] = A(
    () => e.getItem(t) ?? n
  ), s = ae((o) => {
    a(o), e.setItem(t, o);
  }, []);
  return se(() => {
    a(e.getItem(t) ?? n), window.addEventListener("storage", o);
    function o(i) {
      i.storageArea === e && (i.key === null || i.key === t) && a(i.newValue ?? n);
    }
    return () => window.removeEventListener("storage", o);
  }, [e, t]), [l, s];
}
function dt(e, t, n) {
  const l = w(
    () => JSON.stringify(n),
    [n]
  ), [a, s] = ut(
    e,
    t,
    l
  ), o = w(() => JSON.parse(a), [a]), i = ae(
    (c) => s(JSON.stringify(c)),
    []
  );
  return [o, i];
}
const me = ot(
  "HelpContext",
  void 0
), mt = () => ct(me), jt = ({ children: e }) => {
  const [t, n] = dt(localStorage, "helpEnabled", !0);
  function l() {
    n(!t);
  }
  return /* @__PURE__ */ r(me.Provider, { value: { enabled: t, toggleHelp: l }, children: e });
}, fe = ({
  helpText: e,
  fieldLabelId: t,
  noVerticalAlign: n = !0,
  unWrap: l = !1
}) => {
  const { enabled: a } = mt();
  return a ? /* @__PURE__ */ r(Te, { bodyContent: e, children: /* @__PURE__ */ I(P, { children: [
    !l && /* @__PURE__ */ r(
      "button",
      {
        "data-testid": `help-label-${t}`,
        "aria-label": t,
        onClick: (s) => s.preventDefault(),
        className: "pf-c-form__group-label-help",
        children: /* @__PURE__ */ r(z, { noVerticalAlign: n })
      }
    ),
    l && /* @__PURE__ */ r(z, { noVerticalAlign: n })
  ] }) }) : null;
}, V = ({
  name: e,
  label: t,
  labelIcon: n,
  error: l,
  children: a,
  ...s
}) => /* @__PURE__ */ r(
  ie,
  {
    label: t || e,
    fieldId: e,
    labelIcon: n ? /* @__PURE__ */ r(fe, { helpText: n, fieldLabelId: e }) : void 0,
    helperTextInvalid: l?.message,
    validated: l ? v.error : v.default,
    ...s,
    children: a
  }
), he = (e) => typeof e == "string", Q = (e) => he(e) ? e : e.key, ft = ({
  name: e,
  label: t,
  options: n,
  controller: l,
  variant: a = W.single,
  labelIcon: s,
  onFilter: o,
  ...i
}) => {
  const {
    control: c,
    formState: { errors: u }
  } = G(), [h, p] = A(!1), g = (m = "") => {
    const b = m.toLowerCase();
    return n.filter(
      (f) => f.toString().toLowerCase().startsWith(b)
    ).map((f) => /* @__PURE__ */ r(ue, { value: Q(f), children: he(f) ? f : f.value }, Q(f)));
  }, d = (m) => typeof m[0] != "string";
  return /* @__PURE__ */ r(
    V,
    {
      name: e,
      label: t,
      isRequired: !!l.rules?.required,
      error: u[e],
      labelIcon: s,
      children: /* @__PURE__ */ r(
        q,
        {
          ...l,
          name: e,
          control: c,
          render: ({ field: { onChange: m, value: b } }) => /* @__PURE__ */ r(
            ce,
            {
              ...i,
              toggleId: e.slice(e.lastIndexOf(".") + 1),
              onToggle: (f) => p(f),
              selections: d(n) ? n.filter(
                (f) => Array.isArray(b) ? b.includes(f.key) : b === f.key
              ).map((f) => f.value) : b,
              onSelect: (f, S) => {
                if (f.stopPropagation(), Array.isArray(b)) {
                  const N = S.toString(), C = d(n) ? n.find((y) => y.value === N)?.key : N;
                  b.includes(C) ? m(b.filter((y) => y !== C)) : m([...b, N]);
                } else
                  m(S), p(!1);
              },
              onClear: a !== W.single ? (f) => {
                f.stopPropagation(), m([]);
              } : void 0,
              onFilter: (f, S) => (o?.(S), g(S)),
              isOpen: h,
              variant: a,
              validated: u[e] ? v.error : v.default,
              children: g()
            }
          )
        }
      )
    }
  );
}, Ut = (e) => {
  const t = e.stringify ? "false" : !1, n = e.defaultValue ?? t, { control: l } = G();
  return /* @__PURE__ */ r(
    V,
    {
      hasNoPaddingTop: !0,
      name: e.name,
      isRequired: e.rules?.required === !0,
      label: e.label,
      labelIcon: e.labelIcon,
      children: /* @__PURE__ */ r(
        q,
        {
          control: l,
          name: e.name,
          defaultValue: n,
          render: ({ field: { onChange: a, value: s } }) => /* @__PURE__ */ r(
            Se,
            {
              ...e,
              id: e.name,
              "data-testid": e.name,
              label: e.labelOn,
              isChecked: e.stringify ? s === "true" : s,
              onChange: (o, i) => {
                const c = e.stringify ? o.toString() : o;
                e.onChange?.(o, i), a(c);
              }
            }
          )
        }
      )
    }
  );
}, R = $(({ onChange: e, ...t }, n) => /* @__PURE__ */ r(de, { ...t, ref: n, onChange: (a, s) => e?.(s) }));
R.displayName = "TextInput";
const Wt = (e) => {
  const { labelIcon: t, ...n } = e, l = !!e.rules?.required, a = e.defaultValue ?? "", { field: s, fieldState: o } = j({
    ...e,
    defaultValue: a
  });
  return /* @__PURE__ */ r(
    V,
    {
      name: e.name,
      label: e.label,
      labelIcon: t,
      isRequired: l,
      error: o.error,
      helperText: e.helperText,
      children: /* @__PURE__ */ r(
        R,
        {
          isRequired: l,
          id: e.name,
          "data-testid": e.name,
          validated: o.error ? v.error : v.default,
          isDisabled: e.isDisabled,
          ...n,
          ...s
        }
      )
    }
  );
}, U = $(({ onChange: e, ...t }, n) => /* @__PURE__ */ r(ke, { ...t, ref: n, onChange: (a, s) => e?.(s) }));
U.displayName = "TextArea";
const Jt = (e) => {
  const t = !!e.rules?.required, n = e.defaultValue ?? "", { field: l, fieldState: a } = j({
    ...e,
    defaultValue: n
  });
  return /* @__PURE__ */ r(
    V,
    {
      isRequired: t,
      label: e.label,
      labelIcon: e.labelIcon,
      name: e.name,
      error: a.error,
      children: /* @__PURE__ */ r(
        U,
        {
          isRequired: t,
          id: e.name,
          "data-testid": e.name,
          validated: a.error ? v.error : v.default,
          isDisabled: e.isDisabled,
          ...l
        }
      )
    }
  );
}, Kt = ({
  name: e,
  label: t,
  controller: n,
  labelIcon: l,
  ...a
}) => {
  const {
    control: s,
    formState: { errors: o }
  } = G();
  return /* @__PURE__ */ r(
    V,
    {
      name: e,
      label: t,
      isRequired: n.rules?.required === !0,
      error: o[e],
      labelIcon: l,
      children: /* @__PURE__ */ r(
        q,
        {
          ...n,
          name: e,
          control: s,
          render: ({ field: i }) => {
            const c = !!n.rules?.required, u = n.rules?.min, h = i.value === 0 ? n.defaultValue : i.value, p = (g) => i.onChange(u ? Math.max(g, Number(u)) : g);
            return /* @__PURE__ */ r(
              xe,
              {
                ...a,
                id: e,
                value: h,
                validated: o[e] ? v.error : v.default,
                required: c,
                min: Number(u),
                max: Number(n.rules?.max),
                onPlus: () => p(h + 1),
                onMinus: () => p(h - 1),
                onChange: (g) => {
                  const d = Number(g.currentTarget.value);
                  p(isNaN(d) ? n.defaultValue : d);
                }
              }
            );
          }
        }
      )
    }
  );
}, ge = re(void 0), zt = () => le(ge), Yt = ({ children: e }) => {
  const [t, n] = A([]), l = (o) => {
    n((i) => i.filter((c) => c.id !== o));
  }, a = (o, i = D.success, c) => {
    n([
      {
        id: Math.random() * 100,
        message: o,
        variant: i,
        description: c
      },
      ...t
    ]);
  }, s = (o) => {
    a(o, D.danger);
  };
  return /* @__PURE__ */ I(ge.Provider, { value: { addAlert: a, addError: s }, children: [
    /* @__PURE__ */ r(we, { isToast: !0, "data-testid": "alerts", children: t.map(({ id: o, variant: i, message: c, description: u }) => /* @__PURE__ */ r(
      Ae,
      {
        isLiveRegion: !0,
        variant: D[i],
        variantLabel: "",
        title: c,
        actionClose: /* @__PURE__ */ r(
          Ve,
          {
            title: c,
            onClose: () => l(o)
          }
        ),
        timeout: !0,
        onTimeout: () => l(o),
        children: u && /* @__PURE__ */ r("p", { children: u })
      },
      o
    )) }),
    e
  ] });
}, Qt = ({ icon: e }) => {
  const t = ht(e);
  return /* @__PURE__ */ r(t, { size: "lg", alt: e });
};
function ht(e) {
  switch (e) {
    case "github":
      return tt;
    case "facebook":
      return et;
    case "gitlab":
      return Ze;
    case "google":
      return Xe;
    case "linkedin":
    case "linkedin-openid-connect":
      return Qe;
    case "openshift-v3":
    case "openshift-v4":
      return Ye;
    case "stackoverflow":
      return ze;
    case "twitter":
      return Ke;
    case "microsoft":
      return Je;
    case "bitbucket":
      return We;
    case "instagram":
      return Ue;
    case "paypal":
      return je;
    default:
      return Ge;
  }
}
const gt = (e) => {
  try {
    return new Intl.DisplayNames([e], { type: "language" }).of(e);
  } catch {
    return e;
  }
}, bt = ({
  t: e,
  form: t,
  supportedLocales: n
}) => {
  const l = n.map((a) => ({
    key: a,
    value: gt(a) || ""
  }));
  return l.length ? /* @__PURE__ */ r($e, { ...t, children: /* @__PURE__ */ r(
    ft,
    {
      "data-testid": "locale-select",
      name: "attributes.locale",
      label: e("selectALocale"),
      controller: { defaultValue: "" },
      options: l
    }
  ) }) : null;
}, be = (e) => e?.includes("${"), F = (e) => e.substring(2, e.length - 1), E = (e, t, n) => (be(t) ? e(F(t)) : t) || n, M = (e, t) => E(e, t.displayName, t.name), pt = ["username", "firstName", "lastName", "email"], pe = (e) => e && pt.includes(e), k = (e) => `${pe(e) ? "" : "attributes."}${e?.replaceAll(
  ".",
  "🍺"
)}`, Xt = (e) => e.replaceAll(".", "🍺"), Zt = (e) => e.replaceAll("🍺", ".");
function en(e, t, n) {
  (e.responseData.errors !== void 0 ? e.responseData.errors : [e.responseData]).forEach((l) => {
    const a = Object.assign(
      {},
      l.params?.map((s) => n(be(s.toString()) ? F(s) : s))
    );
    t(k(l.field), {
      message: n(l.errorMessage, {
        ...a,
        defaultValue: l.errorMessage || l.field
      }),
      type: "server"
    });
  });
}
function O({
  required: e,
  validators: t
}) {
  return e || yt(t);
}
function yt(e) {
  return e && "length" in e && "min" in e.length && typeof e.length.min == "number" ? e.length.min > 0 : !1;
}
function tn(e) {
  if (typeof e != "object" || e === null || !("responseData" in e))
    return !1;
  const { responseData: t } = e;
  return X(t) ? !0 : typeof t != "object" || t === null || !("errors" in t) || !Array.isArray(t.errors) ? !1 : t.errors.every(X);
}
function X(e) {
  return !(typeof e != "object" || e === null || !("field" in e) || typeof e.field != "string" || !("errorMessage" in e) || typeof e.errorMessage != "string");
}
const L = ({
  t: e,
  form: t,
  attribute: n,
  renderer: l,
  children: a
}) => {
  const s = n.annotations?.inputHelperTextBefore, {
    formState: { errors: o }
  } = t, i = l?.(n);
  return /* @__PURE__ */ r(
    ie,
    {
      label: M(e, n) || "",
      fieldId: n.name,
      isRequired: O(n),
      validated: Y(o, k(n.name)) ? "error" : "default",
      helperTextInvalid: e(
        Y(o, k(n.name))?.message
      ),
      labelIcon: s ? /* @__PURE__ */ r(fe, { helpText: s, fieldLabelId: n.name }) : void 0,
      children: i ? /* @__PURE__ */ I(B, { children: [
        a,
        i
      ] }) : a
    },
    n.name
  );
}, Ct = ({
  t: e,
  form: t,
  attribute: n,
  renderer: l,
  ...a
}) => /* @__PURE__ */ r(L, { t: e, form: t, attribute: n, renderer: l, children: /* @__PURE__ */ r(
  It,
  {
    t: e,
    form: t,
    "aria-label": M(e, n),
    name: k(n.name),
    addButtonLabel: e("addMultivaluedLabel", {
      fieldLabel: M(e, n)
    }),
    ...a
  }
) }), It = ({
  t: e,
  name: t,
  inputType: n,
  form: l,
  addButtonLabel: a,
  isDisabled: s = !1,
  defaultValue: o,
  id: i,
  ...c
}) => {
  const { register: u, setValue: h, control: p } = l, g = Be({
    name: t,
    control: p,
    defaultValue: o || ""
  }), d = w(() => Array.isArray(g) && g.length !== 0 ? g : o || [""], [g]), m = (C) => {
    S([...d.slice(0, C), ...d.slice(C + 1)]);
  }, b = () => {
    S([...d, ""]);
  }, f = (C, y) => {
    S([...d.slice(0, C), y, ...d.slice(C + 1)]);
  }, S = (C) => {
    const y = C.flatMap((_) => _);
    h(t, y, {
      shouldDirty: !0
    });
  }, N = n.startsWith("html") ? n.substring(6) : "text";
  return se(() => {
    u(t);
  }, [u]), /* @__PURE__ */ r("div", { id: i, children: d.map((C, y) => /* @__PURE__ */ I(oe, { children: [
    /* @__PURE__ */ I(B, { children: [
      /* @__PURE__ */ r(
        de,
        {
          "data-testid": t + y,
          onChange: (_) => f(y, _),
          name: `${t}.${y}.value`,
          value: C,
          isDisabled: s,
          type: N,
          ...c
        }
      ),
      /* @__PURE__ */ r(
        x,
        {
          "data-testid": "remove" + y,
          variant: J.link,
          onClick: () => m(y),
          tabIndex: -1,
          "aria-label": e("remove"),
          isDisabled: d.length === 1 || s,
          children: /* @__PURE__ */ r(nt, {})
        }
      )
    ] }),
    y === d.length - 1 && /* @__PURE__ */ I(
      x,
      {
        variant: J.link,
        onClick: b,
        tabIndex: -1,
        "aria-label": e("add"),
        "data-testid": "addValue",
        isDisabled: !C || s,
        children: [
          /* @__PURE__ */ r(rt, {}),
          " ",
          e(a || "add")
        ]
      }
    )
  ] }, y)) });
}, Z = (e) => {
  const { form: t, inputType: n, attribute: l } = e, a = O(l), s = n.startsWith("multiselect"), o = s ? Ne : qe, i = l.validators?.options?.options || [], c = l.annotations?.inputOptionLabels;
  return /* @__PURE__ */ r(L, { ...e, children: /* @__PURE__ */ r(
    q,
    {
      name: k(l.name),
      control: t.control,
      defaultValue: "",
      render: ({ field: u }) => /* @__PURE__ */ r(P, { children: i.map((h) => /* @__PURE__ */ r(
        o,
        {
          id: h,
          "data-testid": h,
          label: e.t(F(c?.on || h)),
          value: h,
          isChecked: u.value.includes(h),
          onChange: () => {
            s ? u.value.includes(h) ? u.onChange(
              u.value.filter((p) => p !== h)
            ) : u.onChange([...u.value, h]) : u.onChange([h]);
          },
          readOnly: l.readOnly,
          isRequired: a
        },
        h
      )) })
    }
  ) });
}, ee = (e) => {
  const { t, form: n, inputType: l, attribute: a } = e, [s, o] = A(!1), i = O(a), c = l === "multiselect", u = (d, m) => {
    c ? m.value.includes(d) ? m.onChange(m.value.filter((b) => b !== d)) : m.onChange([...m.value, d]) : m.onChange(d);
  }, h = a.validators?.options?.options || [], p = a.annotations?.inputOptionLabels, g = (d) => p ? t(F(p[d])) : d;
  return /* @__PURE__ */ r(L, { ...e, children: /* @__PURE__ */ r(
    q,
    {
      name: k(a.name),
      defaultValue: "",
      control: n.control,
      render: ({ field: d }) => /* @__PURE__ */ r(
        ce,
        {
          toggleId: a.name,
          onToggle: (m) => o(m),
          isCreatable: !0,
          onCreateOption: (m) => u(m, d),
          onSelect: (m, b) => {
            const f = b.toString();
            u(f, d), Array.isArray(d.value) || o(!1);
          },
          selections: d.value ? d.value : c ? [] : t("choose"),
          variant: c ? "typeaheadmulti" : "single",
          "aria-label": t("selectOne"),
          isOpen: s,
          isDisabled: a.readOnly,
          required: i,
          children: ["", ...h].map((m) => /* @__PURE__ */ r(
            ue,
            {
              selected: d.value === m,
              value: m,
              children: m ? g(m) : t("choose")
            },
            m
          ))
        }
      )
    }
  ) });
}, vt = (e) => {
  const { form: t, attribute: n } = e, l = O(n);
  return /* @__PURE__ */ r(L, { ...e, children: /* @__PURE__ */ r(
    U,
    {
      id: n.name,
      "data-testid": n.name,
      ...t.register(k(n.name)),
      cols: n.annotations?.inputTypeCols,
      rows: n.annotations?.inputTypeRows,
      readOnly: n.readOnly,
      isRequired: l
    }
  ) });
}, T = (e) => {
  const { form: t, inputType: n, attribute: l } = e, a = O(l), s = n.startsWith("html") ? n.substring(6) : "text";
  return /* @__PURE__ */ r(L, { ...e, children: /* @__PURE__ */ r(
    R,
    {
      id: l.name,
      "data-testid": l.name,
      type: s,
      placeholder: l.annotations?.inputTypePlaceholder,
      readOnly: l.readOnly,
      isRequired: a,
      ...t.register(k(l.name))
    }
  ) });
}, H = {
  text: T,
  textarea: vt,
  select: ee,
  "select-radiobuttons": Z,
  multiselect: ee,
  "multiselect-checkboxes": Z,
  "html5-email": T,
  "html5-tel": T,
  "html5-url": T,
  "html5-number": T,
  "html5-range": T,
  "html5-datetime-local": T,
  "html5-date": T,
  "html5-month": T,
  "html5-time": T,
  "multi-input": Ct
}, nn = ({
  t: e,
  form: t,
  userProfileMetadata: n,
  supportedLocales: l,
  hideReadOnly: a = !1,
  renderer: s
}) => {
  const o = w(() => {
    if (!n.attributes)
      return [];
    const i = a ? n.attributes.filter(({ readOnly: c }) => !c) : n.attributes;
    return [
      // Insert an empty group for attributes without a group.
      { name: void 0 },
      ...n.groups ?? []
    ].map((c) => ({
      group: c,
      attributes: i.filter(
        (u) => u.group === c.name
      )
    }));
  }, [
    a,
    n.groups,
    n.attributes
  ]);
  return o.length === 0 ? null : /* @__PURE__ */ r(
    Rt,
    {
      label: e("jumpToSection"),
      sections: o.filter((i) => i.attributes.length > 0).map(({ group: i, attributes: c }) => ({
        title: E(e, i.displayHeader, i.name) || e("general"),
        panel: /* @__PURE__ */ I("div", { className: "pf-c-form", children: [
          i.displayDescription && /* @__PURE__ */ r(Oe, { className: "pf-u-pb-lg", children: E(e, i.displayDescription, "") }),
          c.map((u) => /* @__PURE__ */ r(
            Tt,
            {
              t: e,
              form: t,
              supportedLocales: l,
              renderer: s,
              attribute: u
            },
            u.name
          ))
        ] })
      }))
    }
  );
}, Tt = ({
  t: e,
  form: t,
  renderer: n,
  supportedLocales: l,
  attribute: a
}) => {
  const s = t.watch(
    k(a.name)
  ), o = w(() => kt(a), [a]), i = a.multivalued || wt(s) ? H["multi-input"] : H[o];
  return a.name === "locale" ? /* @__PURE__ */ r(
    bt,
    {
      form: t,
      supportedLocales: l,
      t: e,
      attribute: a
    }
  ) : /* @__PURE__ */ r(
    i,
    {
      t: e,
      form: t,
      inputType: o,
      attribute: a,
      renderer: n
    }
  );
}, St = "text";
function kt(e) {
  if (pe(e.name))
    return "text";
  const t = e.annotations?.inputType;
  return xt(t) ? t : St;
}
const xt = (e) => typeof e == "string" && e in H, wt = (e) => Array.isArray(e) && e.length > 1, At = "_title_1nyfo_2", Vt = {
  title: At
}, ye = ({
  id: e,
  title: t,
  headingLevel: n = "h1",
  size: l = "xl",
  ...a
}) => /* @__PURE__ */ r(
  Le,
  {
    headingLevel: n,
    size: l,
    className: Vt.title,
    id: e,
    tabIndex: 0,
    ...a,
    children: t
  }
), Nt = ({
  title: e,
  children: t,
  scrollId: n,
  className: l
}) => {
  const a = Ie();
  return /* @__PURE__ */ I(Pe, { id: a, className: l, isFlat: !0, children: [
    /* @__PURE__ */ r(Re, { className: "kc-form-panel__header", children: /* @__PURE__ */ r(Fe, { tabIndex: 0, children: /* @__PURE__ */ r(ye, { id: n, title: e }) }) }),
    /* @__PURE__ */ r(_e, { className: "kc-form-panel__body", children: t })
  ] });
}, qt = (e) => {
  const { title: t, children: n, scrollId: l, ...a } = e;
  return /* @__PURE__ */ r("section", { ...a, style: { marginTop: "var(--pf-global--spacer--lg)" }, children: /* @__PURE__ */ I(P, { children: [
    /* @__PURE__ */ r(ye, { id: l, title: t }),
    n
  ] }) });
}, Ot = "_panel_cd9gh_1", Lt = "_sticky_cd9gh_5", te = {
  panel: Ot,
  sticky: Lt
}, Pt = "kc-main-content-page-container", ne = (e) => e.replace(/\s+/g, "-"), Rt = ({
  label: e,
  sections: t,
  borders: n = !1,
  ...l
}) => {
  const a = w(
    () => t.filter(({ isHidden: s }) => !s),
    [t]
  );
  return /* @__PURE__ */ I(De, { hasGutter: !0, ...l, children: [
    /* @__PURE__ */ r(K, { md: 8, sm: 12, children: a.map(({ title: s, panel: o }) => {
      const i = ne(s.toLowerCase());
      return /* @__PURE__ */ r(oe, { children: n ? /* @__PURE__ */ r(
        Nt,
        {
          scrollId: i,
          title: s,
          className: te.panel,
          children: o
        }
      ) : /* @__PURE__ */ r(qt, { scrollId: i, title: s, children: o }) }, s);
    }) }),
    /* @__PURE__ */ r(K, { md: 4, sm: 12, order: { default: "-1", md: "1" }, children: /* @__PURE__ */ r(Ee, { className: te.sticky, children: /* @__PURE__ */ r(
      Me,
      {
        isVertical: !0,
        scrollableSelector: `#${Pt}`,
        label: e,
        offset: 100,
        children: a.map(({ title: s }) => {
          const o = ne(s.toLowerCase());
          return (
            // note that JumpLinks currently does not work with spaces in the href
            /* @__PURE__ */ r(
              He,
              {
                href: `#${o}`,
                "data-testid": `jump-link-${o}`,
                children: s
              },
              s
            )
          );
        })
      }
    ) }) })
  ] });
}, Ft = ({
  hasReveal: e = !0,
  innerRef: t,
  ...n
}) => {
  const { t: l } = st(), [a, s] = A(!0);
  return /* @__PURE__ */ I(B, { children: [
    /* @__PURE__ */ r(
      R,
      {
        ...n,
        type: a ? "password" : "text",
        ref: t
      }
    ),
    e && /* @__PURE__ */ r(
      x,
      {
        variant: "control",
        "aria-label": l("showPassword"),
        onClick: () => s(!a),
        children: a ? /* @__PURE__ */ r(lt, {}) : /* @__PURE__ */ r(at, {})
      }
    )
  ] });
}, Ce = $(
  (e, t) => /* @__PURE__ */ r(Ft, { ...e, innerRef: t })
);
Ce.displayName = "PasswordInput";
const rn = (e) => {
  const { labelIcon: t, ...n } = e, l = !!e.rules?.required, a = e.defaultValue ?? "", { field: s, fieldState: o } = j({
    ...e,
    defaultValue: a
  });
  return /* @__PURE__ */ r(
    V,
    {
      name: e.name,
      label: e.label,
      labelIcon: t,
      isRequired: l,
      error: o.error,
      helperText: e.helperText,
      children: /* @__PURE__ */ r(
        Ce,
        {
          isRequired: l,
          id: e.name,
          "data-testid": e.name,
          validated: o.error ? v.error : v.default,
          isDisabled: e.isDisabled,
          ...n,
          ...s
        }
      )
    }
  );
};
export {
  Yt as AlertProvider,
  Gt as ContinueCancelModal,
  Nt as FormPanel,
  jt as Help,
  fe as HelpItem,
  Qt as IconMapper,
  U as KeycloakTextArea,
  R as KeycloakTextInput,
  Kt as NumberControl,
  rn as PasswordControl,
  Ce as PasswordInput,
  Rt as ScrollForm,
  ft as SelectControl,
  Ut as SwitchControl,
  Jt as TextAreaControl,
  Wt as TextControl,
  nn as UserProfileFields,
  Xt as beerify,
  ot as createNamedContext,
  Zt as debeerify,
  it as isDefined,
  tn as isUserProfileError,
  E as label,
  Pt as mainPageContentId,
  en as setUserProfileServerError,
  zt as useAlerts,
  mt as useHelp,
  ct as useRequiredContext,
  dt as useStoredState
};
